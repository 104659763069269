<template>
  <nav id="sidebarMenu" class="sidebar">
    <div class="logo-wrapper">
      <b-navbar-brand href="/">
        <span>
         <img src="../assets/img/redline-new-logo.svg" alt="" />
        </span>
      </b-navbar-brand>
      <button @click="toggleBodyClass" class="btn toggle-btn">
        <img src="../assets/img/icon-close.svg" alt="" />
      </button>
    </div>
    <div class="sidebar-wrap">
      <div class="sidebar-up">
        <b-navbar-nav class="main-nav">
          <b-nav-item to="/dashboard" @click="removeBodyClass">
            <div class="img-wrap">
              <img src="../assets/img/dashboard-new.svg" alt="" />
            </div>
            <span>{{ $t('sidebar.dashboard') }}</span>
          </b-nav-item>
          <b-nav-item to="/car-list" class="car-wrapper" @click="removeBodyClass">
            <div class="img-wrap">
              <img src="../assets/img/car-new.svg" alt="" />
            </div>
            <span>{{ $t('sidebar.add_vehicle_parts') }}</span>
          </b-nav-item>
          <b-nav-item to="/part-list" class="parts-wrapper" @click="removeBodyClass">
            <div class="img-wrap">
              <img src="../assets/img/part-new.svg" alt="" />
            </div>
            <span>{{ $t('sidebar.add_individual_parts') }}</span>
          </b-nav-item>
          <b-nav-item to="/scan-barcode" class="scan-wrapper" @click="removeBodyClass">
            <div class="img-wrap">
              <img src="../assets/img/pencil-new.svg" alt="" />
            </div>
            <span>{{ $t('sidebar.barcode_scanner') }}</span>
          </b-nav-item>
          <b-nav-item to="/checkout" class="scan-wrapper" @click="removeBodyClass">
            <div class="img-wrap">
              <img src="../assets/img/cart-icon-new.svg" alt="" />
            </div>
            <span>{{ $t('sidebar.checkout') }}</span>
          </b-nav-item>
        </b-navbar-nav>
      </div>
      <div class="sidebar-bottom">
        <div class="mobile-show">
          <div v-if="user" class="company-name">
            <span>
              <img src="../assets/img/icon-user.svg" alt="" />
            </span>
            <p>
              <b>{{ user.name }}</b>
              <label>{{ user.email }}</label>
            </p>
          </div>
        </div>
        <div class="need-assitance">
          <a class="custom-btn pattern-btn blue-ptn" href="tel:+1 714 334 5505">
            <span>
              <img src="../assets/img/icon-phone-call.svg" />
              {{ $t('sidebar.help_text') }}
              <b>714-334-5505</b>
            </span>
          </a>
        </div>        
        <b-navbar-nav class="main-logout">
          <!-- <p v-if="user" class="company-name">{{ user.name }}</p> -->
          <a href="javascript:void(0)" @click="doLogout" class="btn custom-btn pattern-btn" class-active="">
            <span class="custom-flex">
              <div class="img-wrap">
                <img class="btn-log-out-img" src="../assets/img/logout-new.svg" alt="" />
              </div>
              {{ $t('sidebar.logout') }} 
              <img src="../assets/img/right-arrow.svg" alt="">
            </span>
          </a>
        </b-navbar-nav>
      </div>
    </div>
    </b-navbar-nav>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { showSuccess, showFailure } from "../eventbus/action.js";
export default {
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    showSidebar() {
      return this.$route.name !== "Login";
    },
  },
  methods: {
    toggleBodyClass() {
      document.body.classList.toggle('sidebar-active');
    },
    removeBodyClass() {
      document.body.classList.remove('sidebar-active');
    },
    
    ...mapActions({
      logout: "user/logout",
    }),
    async doLogout() {
      try {
        this.$store.commit("setLoader", true);
        await this.logout();
        this.$router.push({ name: "/", query: { isRedirect: true } });
        document.body.classList.toggle('sidebar-active');
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
  }
};
</script>

<style scoped></style>
