<template>
  <div>
    <div class="container-fluid px-xl-4 py-4">
      <!-- statistics -->
      <div class="statistics">
        <!-- component title -->
        <div class="page-header">
          <h2>{{ $t('dashboard.my_inventory_statistics') }}</h2>
        </div>
        <!-- component title -->
        <div class="row">
          <div class="statistics-boxs pattern-green col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div class="s-box-body custom-btn  pattern-btn">
              <div class="s-box-header">
                <h4 class="statistics-title">{{ $t('dashboard.total_vehicles_by') }} </br> {{ $t('dashboard.brand') }}</h4>
                <model-select :options="makeList" id="input-3" type="text" :placeholder="$t('dashboard.by_brand')"
                  v-model="make_for_vehicle" @input="getDashboardData()">
                </model-select>
              </div>
              <div class="s-box-footer">
                <div class="s-box-f-l">
                  <h1 class="number">
                    {{
                      dashboard.total_vehicles_per_brand &&
                      dashboard.total_vehicles_per_brand.count
                    }}
                  </h1>
                  <span class="price-text">{{ $t('dashboard.count') }}</span>
                </div>

                <div class="s-box-f-r">
                  <h1 class="number">
                    {{
                      dashboard.total_vehicles_per_brand &&
                      dashboard.total_vehicles_per_brand.sum.toFixed(2)
                      | USCurrencyfilter
                    }}
                  </h1>
                  <span class="price-text">{{ $t('dashboard.value') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="statistics-boxs pattern-green col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div class="s-box-body custom-btn  pattern-btn">
              <div class="s-box-header">
                <h4 class="statistics-title">{{ $t('dashboard.total_parts_by') }}</br> {{ $t('dashboard.brand') }}</h4>
                <model-select :options="makeList" id="input-3" type="text" :placeholder=" $t('dashboard.by_brand')"
                  v-model="make_for_part" @input="getDashboardData()">
                </model-select>
              </div>
              <div class="s-box-footer">
                <div class="s-box-f-l">
                  <h1 class="number">
                    {{
                      dashboard.total_inventory_parts_per_car_brand &&
                      dashboard.total_inventory_parts_per_car_brand.count
                    }}
                  </h1>
                  <span class="price-text">{{ $t('dashboard.count') }}</span>
                </div>

                <div class="s-box-f-r">
                  <h1 class="number">
                    {{
                      dashboard.total_inventory_parts_per_car_brand &&
                      dashboard.total_inventory_parts_per_car_brand.sum.toFixed(
                        2
                      ) | USCurrencyfilter
                    }}
                  </h1>
                  <span class="price-text">{{ $t('dashboard.value') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="statistics-boxs pattern-green col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div class="s-box-body custom-btn  pattern-btn">
              <div class="s-box-header">
                <h4 class="statistics-title">{{ $t('dashboard.total_parts_by') }} </br> {{ $t('dashboard.part_name') }}</h4>
                <model-select :options="partsNameOption" type="text" :placeholder="$t('dashboard.by_part_name')" v-model="part_name_for_part"
                  @input="getDashboardData()">
                </model-select>
              </div>
              <div class="s-box-footer">
                <div class="s-box-f-l">
                  <h1 class="number">
                    {{
                      dashboard.total_inventory_parts_per_part_name &&
                      dashboard.total_inventory_parts_per_part_name.count
                    }}
                  </h1>
                  <span class="price-text">{{ $t('dashboard.count') }}</span>
                </div>

                <div class="s-box-f-r">
                  <h1 class="number">
                    {{
                      dashboard.total_inventory_parts_per_part_name &&
                      dashboard.total_inventory_parts_per_part_name.sum.toFixed(
                        2
                      ) | USCurrencyfilter
                    }}
                  </h1>
                  <span class="price-text">{{ $t('dashboard.value') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="statistics-boxs col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="s-t-body">
              <h4 class="statistics-title">{{ $t('dashboard.total_vehicle_purchased') }}</h4>
              <div class="s-t-wrap">
                <div class="s-t-l custom-btn pattern-btn blue-ptn">
                  <span>
                    <h1>
                      {{
                        dashboard.total_vehicles_purchased &&
                        dashboard.total_vehicles_purchased.count
                      }}
                    </h1>
                    <span class="price-text">{{ $t('dashboard.count') }}</span>
                  </span>
                </div>
                <div class="s-t-r custom-btn pattern-btn">
                  <span>
                    <h1> {{
                      dashboard.total_vehicles_purchased &&
                      dashboard.total_vehicles_purchased.sum.toFixed(2)
                      | USCurrencyfilter
                    }}</h1>
                    <span class="price-text">{{ $t('dashboard.value') }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="statistics-boxs col-xl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="s-t-body">
              <h4 class="statistics-title">{{ $t('dashboard.total_inventory_parts') }}</h4>
              <div class="s-t-wrap">
                <div class="s-t-l custom-btn pattern-btn blue-ptn">
                  <span>
                    <h1>
                      {{
                        dashboard.total_inventory_parts_and_value &&
                        dashboard.total_inventory_parts_and_value.count
                      }}
                    </h1>
                    <span class="price-text">{{ $t('dashboard.count') }}</span>
                  </span>
                </div>
                <div class="s-t-r custom-btn pattern-btn">
                  <span>
                    <h1> {{
                      dashboard.total_inventory_parts_and_value &&
                      dashboard.total_inventory_parts_and_value.sum.toFixed(2)
                      | USCurrencyfilter
                    }}</h1>
                    <span class="price-text">{{ $t('dashboard.value') }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- statistics -->
      </div>

      <!-- other member -->
      <div class="other-user-invetory">
        <!-- component title -->
        <div class="page-header">
          <h2>{{ $t('dashboard.other_member_inventroy') }}</h2>
        </div>
        <!-- component title -->
        <div class="row">
          <div class="statistics-boxs other-member-in col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
            <div class="s-t-body">
              <div class="s-box-header">
                <h4 class="statistics-title">{{ $t('dashboard.brands') }}</h4>
                <model-select :options="brandsOption" id="input-3" type="text" :placeholder="$t('dashboard.by_brand')"
                  v-model="total_parts_by_brand">
                </model-select>
              </div>
              <div class="s-t-wrap">
                <div class="s-t-l custom-btn pattern-btn blue-ptn">
                  <span>
                    <h1>
                      {{ extractNumber(total_parts_by_brand) }}
                    </h1>
                    <span class="price-text">{{ $t('dashboard.count') }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="statistics-boxs other-member-in col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
            <div class="s-t-body">
              <div class="s-box-header">
                <h4 class="statistics-title">{{ $t('dashboard.parts') }}</h4>
                <model-select :options="partsOption" id="input-3" type="text" :placeholder="$t('dashboard.by_part')"
                  v-model="total_parts_by_part">
                </model-select>
              </div>
              <div class="s-t-wrap">
                <div class="s-t-l custom-btn pattern-btn blue-ptn">
                  <span>
                    <h1>
                      {{ extractNumber(total_parts_by_part) }}
                    </h1>
                    <span class="price-text">{{ $t('dashboard.count') }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="statistics-boxs other-member-in col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div class="s-t-body">
              <div class="s-box-header">
                <h4 class="statistics-title">{{ $t('dashboard.dismantler') }}</h4>
                <model-select :options="membersOption" id="input-3" type="text" :placeholder="$t('dashboard.by_dismantler')"
                  v-model="total_parts_by_dismantler">
                </model-select>
              </div>
              <div class="s-t-wrap">
                <div class="s-t-l custom-btn pattern-btn blue-ptn">
                  <span>
                    <h1>
                      {{ extractNumber(total_parts_by_dismantler) }}
                    </h1>
                    <span class="price-text">{{ $t('dashboard.count') }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- other member -->
      </div>
    </div>
  </div>
</template>

<script>
import { showFailure } from "../eventbus/action.js";
import { ModelSelect } from "vue-search-select";
import { mapGetters, mapActions } from "vuex";
import $ from "jquery";
import { debounce } from "lodash";
export default {
  components: {
    ModelSelect,
  },
  mounted() {
    this.getMakeOptions();
    this.getDashboardData();
    this.getCarNameDetails();
    this.getBrandsOptions();
    this.getPartsOptions();
    this.getMembersOptions();
    const self = this;
    $("#input-group-6 input").bind("keyup", function (e) {
      console.log(e);
      self.filterParts();
    });
  },
  data() {
    return {
      makeList: [],
      make_for_part: "",
      make_for_vehicle: "",
      part_name_for_part: "",
      total_parts_by_brand: null,
      total_parts_by_part: null,
      total_parts_by_dismantler: null,
      partsNameOption: [],
      partsOption:[],
      membersOption:[],
      brandsOption:[],
    };
  },
  computed: {
    ...mapGetters({
      makeOptions: "partlist/getMake",
      partsList: "partlist/getPartNames",
      dashboard: "dashboard/getDashboard",
      makes: "dashboard/getDashboardMakes",
      parts: "dashboard/getDashboardParts",
      members: "dashboard/getDashboardMembers",
    }),
  },
  methods: {
    ...mapActions({
      getMake: "partlist/getMake",
      getPartName: "partlist/getPartNames",
      getDashboard: "dashboard/getDashboard",
      getDashboardMakes: "dashboard/getDashboardMakes",
      getDashboardParts: "dashboard/getDashboardParts",
      getDashboardMembers: "dashboard/getDashboardMembers",
    }),

    filterParts: debounce(function () {
      this.getCarNameDetails();
    }, 1000),

    async getCarNameDetails() {
      try {
        const val = $("#input-group-6 input").val();
        this.$store.commit("setLoader", true);
        await this.getPartName({ search: val });
        console.log(this.partsList);
        this.partsNameOption = this.partsList.map(function (val) {
          return {
            value: val.name,
            text: val.name,
          };
        });
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    // partsOption
    async getPartsOptions() {
      try {
        this.$store.commit("setLoader", true);
        await this.getDashboardParts();
        this.partsOption = this.parts.map(function (val) {
          return {
            value: val,
            text: val.replace(/\(\d+\)/, '').trim(),
          };
        });
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    // membersOption
    async getMembersOptions() {
      try {
        this.$store.commit("setLoader", true);
        await this.getDashboardMembers();
        this.membersOption = this.members.map(function (val) {
          return {
            value: val,
            text: val.replace(/\(\d+\)/, '').trim(),
          };
        });
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    // brandsOption
    async getBrandsOptions() {
      try {
        this.$store.commit("setLoader", true);
        await this.getDashboardMakes();
        this.brandsOption = this.makes.map(function (val) {
          return {
            value: val,
            text: val.replace(/\(\d+\)/, '').trim(),
          };
        });
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async getMakeOptions() {
      try {
        this.$store.commit("setLoader", true);
        await this.getMake();
        this.makeList = this.makeOptions.map(function (val) {
          return {
            value: val,
            text: val,
          };
        });
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async getDashboardData() {
      try {
        this.$store.commit("setLoader", true);
        await this.getDashboard({
          make_for_vehicle: this.make_for_vehicle,
          make_for_part: this.make_for_part,
          part_name_for_part: this.part_name_for_part,
        });
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    extractNumber(str) {
      if(str) {
        const regex = /\((\d+)\)/;
        const match = str.match(regex);
        return match ? match[1] : null;
      } else {
        return 0;
      }
    }
  },
};
</script>
